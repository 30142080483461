body {
    overflow-x: hidden;
}

.backdrop {
    position: fixed;
    top: 0;
    bottom:0;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: .45);
    z-index: 998;
}

.btn {
    &.action {
        min-width: 150px;

        @include media-breakpoint-down(md) {
            display: block;
            min-width: 100%;
            margin: 0 0 10px 0 !important;
        }
    }
    i {
        font-size: 18px;
    }

    &.btn-link {
        i {
            font-size: 15px;
        }
    }
}

.table {
    tbody {
        min-width: 100%;
    }
    td {
        vertical-align: middle;
    }
}

.main-view {
    .table {
        @include media-breakpoint-down(md) {
            th, td {
                white-space: nowrap;
            }
        }
    }
}

.gmnoprint {
    button {
        min-height: 40px;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}
.custom-control {
    @include media-breakpoint-down(lg) {
        float: none !important;
        margin-bottom: 5px;
    }
}

.modal-center {
    margin-top: 0 !important;
    top: 50% !important;
    transform: translate(0%, -50%) !important;
}

.modal-full-width {
    max-height: 98%;
    .modal-dialog {
        max-width: 95% !important;
    }
}

.card-header[aria-expanded="true"] {
    i.bi-chevron-down {
        transform: rotate(180deg);
    }
}

.custom-control-button {
    padding: 10px !important;
    font-size: 14px !important;
}

.scroll-top {
    display: none;
    height: 50px;
    width: 50px;
    bottom: 30px;
    right: 30px;
    padding: 6px 12px;
    font-size: 25px;
    z-index: 996;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    @include media-breakpoint-down(lg) {
        right: 10px;
        bottom: 10px;
    }
}
.insight-loader {
    z-index: 500;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    text-align: center;
    .loading-message {
        margin-bottom: 10px;
        font-size: 1.2rem;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    .loader {
        position: relative;
    }
}

@include media-breakpoint-down(lg) {
    .main-view {
        .container-fluid {
            max-width: 100%;
        }
    }

    h1 {
        font-size: 1.8rem;
    }

    h2 {
        font-size: 1.4rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size: 1rem;
    }

    h5 {
        font-size: 0.9rem;
    }

}

@include media-breakpoint-down(sm) {
    .main-view {
        .btn {
            display: block;
            min-width: 100%;
            margin: 10px 0 0 !important;

            &.btn-link {
                margin-top: 0 !important;
            }
        }
        .btn,
        .btn-lg,
        .btn-sm {
            padding: 12px;
            font-size: 1rem;
            line-height: 1.1rem;
        }
        .table {
            .btn,
            .btn-lg,
            .btn-sm {
                display: inline-block;
                min-width: auto;
                margin-top: 0 !important;
                padding: 8px;
                font-size: 0.8rem;
            }

            td {
                min-width: 80px;
            }
        }
        .card-sm {
            border: none;
        }
        .list-group {
            .list-group-item {
                padding: 10px 0 !important;
                border: none;
                border-bottom: 1px solid $light-gray;
            }
        }
    }

    body p,
    .lead {
        font-size: 0.8rem;
        font-weight: normal;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.1rem;
    }

    h4 {
        font-size: 1rem;
    }

    h5 {
        font-size: 0.9rem;
    }
}
