.switch {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    border: 0;
    outline: 0;
    cursor: pointer;
    margin-left: 10px;

    &:after {
        content: '';
        width: 60px;
        height: 26px;
        display: inline-block;
        background: rgba(196, 195, 195, 0.55);
        border-radius: 16px;
        clear: both;
    }

    &:before {
        content: '';
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        left: 0;
        top: -3px;
        border-radius: 50%;
        background: rgb(255, 255, 255);
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
    }

    &:checked:before {
        left: 30px;
        box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
    }

    &:checked:after {
        background: $success;
    }

    @include media-breakpoint-down(lg) {
        &:after {
            height: 24px;
        }

        &:before {
            width: 28px;
            height: 28px;
        }
    }
}