.presentation-mode {
    .insight-header .insight-main-nav {
        display: none;
    }

    .filter-nav {
        display: none;
    }

    .chart-view {
        transform-origin: center top;
        -webkit-transform-origin: center top;
        overflow: hidden;

        .filter-nav-toggle {
            display: inline-block !important;
        }
    }

    .map-container,
    .chart-container {
        min-height: auto;
        height: 400px;


        @include media-breakpoint-down(xxl) {
            min-height: auto;
            height: 320px;
        }
    }

}