.navbar {
    .navbar-nav {
        .nav-item {
            .nav-link {
                padding: 15px 8px;
                color: $white;
                font-size: 11px;
                font-weight: bold;
            }
        }
    }

    .dropdown {
        min-width: 200px;

        .dropdown-menu {
            padding: 0;
        }

        .dropdown-item {
            border-bottom: 1px solid $light-gray;
            line-height: 35px;
            
            .switch {
                margin-top: 5px;
            }

            label {
                margin: 0;
            }

            &:last-child {
                border-bottom: none;
            }
        }

    }
}