.filter-nav {
    min-width: 380px;
    max-width: 380px;
    border-right: 1px solid $dark;
    background-color: $white;
    //overflow-y: auto;

    @media (max-width: 1800px) {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 997;

        .compare-filter-nav {
            display: block;
            width: 100%;
            left: 100%;
        }
    }

    @include media-breakpoint-down(xl) {
      min-width: 290px;
      max-width: 290px;
    }


    .filter-nav-toggle {
        display: none;
        position: absolute;
        right: -52px;
        padding: 5px 10px 0;
        min-height: 56px;
        background-color: $white;
        cursor: pointer;
        border-right: 1px solid $dark;
        border-bottom: 1px solid $dark;

        i {
            font-size: 30px;
        }

        @include media-breakpoint-down(md) {
            min-height: 51px;
            right: -47px;

            i {
                font-size: 25px;
            }
        }

        @media (max-width: 1800px) {
            display: block;
        }
    }

    .accordion {
        overflow-y: auto;
        max-height: 100%;

        .card {
            max-height: 450px;
            border: none;
            border-bottom: 1px solid $dark;
            border-radius: 0;

            .card-header {
                padding: 0 15px;
                background-color: $white;
                color: $dark;
                cursor: pointer;

                p {
                    line-height: 55px;
                    font-size: 1.1rem;
                }

                i {
                    margin: 0 15px;
                    float: left;
                    font-size: 1.5rem;
                }

                @include media-breakpoint-down(lg) {
                    p {
                        font-size: 0.9rem;
                    }

                    i {
                        font-size: 1.2rem;
                    }
                }

                @include media-breakpoint-down(md) {
                    p {
                        line-height: 50px;
                        font-size: 0.8rem;
                    }

                    i {
                        font-size: 1rem;
                    }
                }
            }

            .card-body {
                min-height: 380px;
                padding: 5px 30px;
            }

            .collapse {
                overflow-y: scroll;
            }
        }
        .card[aria-expanded="true"] {
            flex-grow: 1;
        }
    }

    .form-check {
        padding-left: 0;
        margin: 10px 0;

        .form-check-label {
            max-width: 85%;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;

            @include media-breakpoint-down(md) {
                max-width: 170px;
            }
        }

    }

    .sub-filters-toggle {
        margin: -3px 5px 0 -15px;
        float: left;
        font-size: 16px;
        cursor: pointer;

        &.open {
            transform: rotate(90deg);
        }
    }

    .sub-filters-toggle-title {
        cursor: pointer;
    }

    .sub-filters {
        display: none;
        margin-left: 15px;

        &.show {
            display: block;
        }
    }

    .compare-filter-nav {
        display: none;
        position: absolute;
        right: -380px;
        width: 380px;
        top: 0;
        bottom: 0;
        background-color: $primary;
        border-left: 1px solid $blue;
        z-index: 997;

        .card {
            border-bottom-color: $light-gray;
            background-color: $primary;

            .card-header {
                background-color: $primary;
                color: $light-gray;
            }
        }

        label, i {
            color: $white;
        }

        .custom-form-check-input {
            border-color: $white;
        }

    }

    .compare-filter-nav-toggle {
        .bi-chevron-right {
            float: right !important;
            color: $gray;
        }

        &.open {
            .bi-chevron-right {
                transform: rotate(180deg);
            }
        }
    }
}
