.main-nav {
    margin-right: 40px;
    padding: 15px 20px;
    background-color: $primary;
    z-index: 999;

    .nav-toggle {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        .icon-bar {
            margin-top: -4px;
            padding: 0 5px;
            span {
                display: block;
                width: 30px;
                height: 4px;
                margin-top: 4px;
                background-color: $white;
                border-radius: 0;
            }
        }

        p {
            display: inline-block;
            margin: 0 0 0 5px;
            font-size: 20px; 
            font-weight: bold;
            color: $white;
        }
    }

    @include media-breakpoint-down(lg) {
        margin-left: -1px;
        margin-right: 20px;
        padding: 10px;

        .nav-toggle {
            .icon-bar {
                span {
                    height: 3x;
                    margin-top: 3px;
                }
            }
        }
    
    }

    .main-nav-collapse {
        position: absolute;
        left: 0;
        width: 450px;
        margin-top: 15px;
        z-index: 999;

        @include media-breakpoint-down(lg) {
            margin-top: 10px;
            width: 100%;
            left: 0;
        }

        .accordion {
            .card {
                border-radius: 0;
                border-right: none;
                border-left: none;

                .card-header {
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 55px;
                    padding: 0 30px;
                    background-color: $primary;
                    cursor: pointer;

                    p {
                        font-size: 16px;
                    }

                    i {
                        font-size: 30px;
                    }
                }

                .card-header[aria-expanded="true"] {
                    i {
                        transform: rotate(180deg);
                    }
                }

                .card-body {
                    padding: 0;

                    .nav {
                        .nav-item {
                            border-bottom: 1px solid $gray;

                            .nav-link {
                                padding: 15px 40px;
                                color: $dark;
                                font-size: 16px;
                                font-weight: bold;
                            }

                            &:last-child {
                                border-bottom: 0;
                            }
                        }
                    }
                }

                &:first-child {
                    border-top-color: $gray;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}