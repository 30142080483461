.sidebar {
    min-height: 100vh;
    width: 18rem;

    a {
        color: $light;

        i {
            font-size: 1rem;
            color: $light-gray;
        }
    }

    .sidebar-divider {
        margin: 0.5rem 0 0.2rem;
        border-top: 1px solid rgba(250, 250, 250, 0.15);
    }
}