.chart-view {
    &.active-fullscreen {
        transform: none !important;
        -webkit-transform: none !important;
    }

    .chart-filters {
        display: flex;
        flex-wrap: wrap;
        min-height: 100%;

        button {
            height: 34px;
            margin-bottom: 10px;
            font-size: .8rem;

            i {
                line-height: 22px;
            }
        }

        .chart-sub-filters-toggle,
        .filter-nav-toggle,
        .time-span {
            margin-right: 5px;
        }

        .chart-filter,
        .chart-filter-reset {
            display: none;
        }

        .chart-filter {
            padding-right: 10px;
            margin-right: 10px;

            span {
                display: inline-block;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            i {
                margin-top: -1px;
                margin-left: 4px;
                float: right;
            }
        }

        .chart-sub-filters-toggle {
            display: none;

            &.active {
                display: inline-block;
            }
        }
    }

    .map-container,
    .chart-container {
        min-height: 420px;
        max-height: 420px;

        @include media-breakpoint-down(md) {
            min-height: auto;
            height: 360px;
        }  
    }
    .chart-container-high {
      min-height: 480px;
      max-height: 480px;

      @include media-breakpoint-down(md) {
        min-height: auto;
        height: 360px;
      }
    }

    .map-container {
        .gm-ui-hover-effect img { 
            content: url('../images/close-icon.svg');
        }
    }
    
    .insight-chart canvas,
    .insight-table {
        border: 1px solid $light-gray;
        border-radius: $border-radius-sm;
        border-width: 1px !important;
    }

    .insight-table {
        position: relative;
        max-height: 100%;
        padding: 15px 20px;

        .table-container {
            max-height: 90%;
            overflow: scroll;

            .bootstrap-table .fixed-table-container .table thead th .sortable {
                padding: 5px 20px 5px 0px !important;
            }

            .fixed-table-toolbar {
                position: absolute;
                top: -5px;
                right: 10px;

                .btn {
                    color:#888A87;
                    border: none !important;
                    background-color: transparent !important;
                    &:focus {
                        outline: none !important;
                        box-shadow: none !important;
                    }
                }
            }

            table {
                th, td {
                    border-color: 1px solid $light-gray;
                    font-size: 0.9rem;
                    vertical-align: top !important;
                    color: #555;
                    padding: 5px 10px !important;
                }

                td {
                    min-width: 100px;
                }
            }

            .fullscreen {
                padding: 0 20px 20px;

                .fixed-table-toolbar {
                    position: static;
                }
            }
        }
        
        h2 {
            font-size: 18px;
            font-weight: bold;
            color: #555;
        }
    }

    .data-table {
        td {
            min-width: 110px;
            padding: 5px !important;
            font-size: 12px;
            line-height: 12px;
        }
    }
    
    .fullscreen-chart {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        padding: 20px 40px;
        z-index: 996;
        background-color: $white;
        
        @include media-breakpoint-down(lg) {
            padding: 20px 10px;
        }
    }

    .fullscreen-chart-cover {
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        padding: 20px 40px;
        z-index: 999;
        background-color: $white;
        
        // Styling for close button in data view
        div > h4 + div + div {
            bottom: auto;
            top: 3px;
            & > div {
                padding: 10px !important;
                margin-bottom: 10px;
                font-size: 15px;
                min-width: 70px;
                text-align: center;
            }
        }
        
        @include media-breakpoint-down(lg) {
            padding: 20px 10px;
        }
    }

    .fullscreen-scroll div:nth-child(3) {
      overflow: scroll;
    }

    .custom-control-button i {
        font-size: 18px;
    }

    .gm-style .gm-style-iw-t::after {
        background: $primary !important;
    }

    .gm-style-iw {
        background-color: $primary;

        .gm-style-iw-d {
            overflow: auto !important;

            &::-webkit-scrollbar {
                width: 15px;
            }
               
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-track-piece  {
                background-color: $primary;
            }
               
            &::-webkit-scrollbar-thumb {
                height: 35px;
                background-color: $blue;
                border: 2px solid transparent;
            }
        }

        div.info {
            max-width: 680px;
            padding-right: 15px;
            color: $white;

            @include media-breakpoint-down(lg) {
                max-width: 450px;
            }

            h2 {
                font-size: 1.1rem;
            }

            hr {
              border-color: $white;
            }

            ul.info {
                list-style: none;
                padding: 0;
                user-select: initial;
                -webkit-user-select: initial;

                li {
                    div {
                        &.closed {
                            overflow: hidden;
                            height: 0;
                        }
                    }
                    table {
                        padding-left: 0;

                        tr td {
                            padding: 5px;
                            vertical-align: top;
                        }
                    }


                    h2 {
                        &::before {
                            content: '- '; 
                        }

                        &.closed {
                            &::before {
                                content: '+ ';
                            }
                        }
                    }

                    h3 {
                        font-size: 0.9rem;
                        cursor: pointer;

                        &::before {
                            content: '- '; 
                        }

                        &.closed {
                            &::before {
                                content: '+ ';
                            }
                        }
                    }

                }
            }
        } 
    }

    .table-title {
        margin-top: 0;
        position: static;

        @include media-breakpoint-up(md) {
            position: absolute;
            margin-top: 10px;
        }
    }

    .bootstrap-table {
        .search-input {
            min-height: 41px
        }

        th, td {
            &.expand-field {
                min-width: 200px;
            }

            &.expand-field-lg {
                min-width: 340px;
            }
        }

        td {
            cursor: pointer;
        }
    }

  .map-heading {
    font-size: 18px;
    font-weight: bolder;
  }

  .map-info {
    font-size: 12px;
  }
    
}