$darkmode-primary: #181C35;
$darkmode-secondary: #2A304C;
$darkmode-dark: #110D2B;
$darkmode-border: #4b4e69;

.darkmode {
    background-color: $darkmode-primary;

    .navbar {
        .dropdown {
            .dropdown-menu {
                background-color: $darkmode-secondary !important;

                .dropdown-item {
                    color: $light-gray;
                    border-color: $darkmode-border !important;

                    &:hover,
                    &:focus {
                        background-color: $darkmode-primary !important;
                    }
                }
            }
        }
    }

    .action-nav {
        background-color: $darkmode-primary;
    }

    .bg-blue {
        background-color: $darkmode-secondary !important;
    }

    .demography-section {
      color: $light-gray;
      border-color: $light-gray;
      a {
        color: $light-gray;
        &:hover {
          color: #fff;
        }
      }
    }

  .map-container  {
    color: $light-gray !important;
  }

    .table-dark {
      background-color: $darkmode-dark;
    }

    &.main-view {
        h1 {
            color: $light-gray;
        }

        .card,
        .card-header {
            h1, h2, h3, h4 {
                color: $dark !important;
            }
        }
    }

    .filter-nav {
        background-color: $darkmode-primary;
        border-color: $darkmode-border !important;

        .card {
            border-bottom-color: $darkmode-border !important;
            background-color: $darkmode-primary !important;

            .card-body,
            .card-header {
                background-color: $darkmode-primary !important;
                border-color: $darkmode-border !important;
                color: $white !important;
            }
        }

        .filter-nav-toggle {
            background-color: $darkmode-primary !important;
            border-right-color: $darkmode-border !important;
            border-bottom-color: $darkmode-border !important;
        }

        .compare-filter-nav {
            background-color: $darkmode-secondary !important;
            border-left: 1px solid $darkmode-border;

            .card {
                background-color: $darkmode-secondary !important;

                .card-body,
                .card-header {
                    background-color: $darkmode-secondary !important;
                }
            }
        }

        .custom-form-check-input {
            border: 1px solid $light-gray;

            &.checked-children {
                box-shadow: inset 0px 0px 1px 2px $darkmode-primary;
                background-color: $light-gray;
            }

            &:checked {
                background-color: $light-gray;
            }

            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjMDAwMDAwIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==");
            }
        }

        .filter-nav-toggle {
            color: $light-gray !important;
        }
    }

    .filters-collapse {
        background-color: $darkmode-secondary !important;

        .filter-apply {
            background-color: $darkmode-primary !important;
        }
    }

    .main-nav {
        background-color: $darkmode-dark !important;

        .card-header {
            background-color: $darkmode-dark !important;
        }

        .card,
        .nav-item {
            border-color: $darkmode-secondary !important;
        }

        .card-body {
            background-color: $darkmode-primary;

            .nav-link {
                color: $light-gray !important;
            }
        }
    }

    .action-nav {
        background-color: $darkmode-primary;
        border-color: $darkmode-border !important;

        .nav-link {
            color: $light-gray !important;
        }

        i {
            color: #ccc !important;

        }

        .chart-statistic {
            p {
                color: $light-gray;
            }

        }

        .action-collapse {
            background-color: $darkmode-primary;
            color: $light-gray !important;
            border-color: $darkmode-border !important;

            .nav-item {
                border-color: $darkmode-border !important;
            }
        }

        .dropdown-menu {
            background-color: $darkmode-primary;

            .dropdown-item {
                color: $light-gray !important;
                border-color: $darkmode-border !important;

                &:hover {
                    background-color: $darkmode-primary;
                }
            }
        }

        .filter-nav-toggle {
            i {
                color: $gray-500 !important;
            }
            &.open {
                i {
                    color: $light-gray !important;
                }
            }
        }

        &.text-secondary {
            color: $light-gray !important;
        }
    }

    .hit-count {
        color: $light-gray !important;
    }

    .chart-view {
        .filter-nav-toggle {
            color: $white !important;
            border-color: $white !important;
        }

        .chart-statistic {
            p {
                color: $gray-500;
            }

            div {
                color: $light-gray;
            }
        }

        .chart-filters {
            button {
                border-color: $light-gray !important;
                color: $light-gray !important;

                i {
                    color: $light-gray !important;
                }
            }
        }

        .insight-chart canvas,
        .insight-table {
            border: none !important;
        }

        .insight-table {
            background-color: $darkmode-dark;

            h2 {
                color: $light;
            }

            table {
                th, td {
                    color: $light;
                    border-color: $darkmode-border;
                }
            }

            .fixed-table-toolbar {
                .btn {
                    color: $light !important;
                }
            }

            .fullscreen {
                background-color: $darkmode-primary;
                .th-inner {
                    padding: 0 !important;
                }
                table {
                    th {
                        padding-right: 0 !important;
                    }
                    th, td {
                        background-color: $darkmode-primary;
                        border-color: $darkmode-border;
                        color: $light;
                    }
                }
            }
        }

        .fullscreen-chart,
        .fullscreen-chart-cover {
            background-color: $darkmode-primary !important;
        }

        .text-secondary {
            color: $light-gray !important;
        }
    }

    .modal-body,
    .modal-content {
        background-color: $darkmode-primary;
        color: $light-gray !important;

        .modal-header,
        .modal-footer {
            border-color: $darkmode-border !important;
        }

        .modal-footer {
            .btn-default {
                color: $light-gray;
            }
        }

        .card {
            background-color: $darkmode-secondary;
        }

        .form-control {
            background-color: #2F334C !important;
            border-color: $darkmode-border;
            color: $light-gray;
        }
    }

    .gm-style .gm-style-iw-t {
        &::after {
          background: $darkmode-secondary !important;
        }
        .gm-ui-hover-effect>span {
            background-color: #fff;
        }
    }

    .gm-style-iw {
        background-color: $darkmode-secondary !important;

        .gm-style-iw-d {
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-track-piece  {
                background-color: $darkmode-secondary !important;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $darkmode-border !important;
                border: 2px solid transparent;
            }
        }

        div.info {
            max-width: 680px;
            padding-right: 15px;
            color: $white;

            hr {
              border-color: $white;
            }

            @include media-breakpoint-down(lg) {
                max-width: 450px;
            }
        }
    }

    .table-title {
        color: $light;
    }

    .data-table {
        border-color: $darkmode-border !important;
        th, td {
            color: $light !important;
            border-color: $darkmode-border !important;
        }
    }

    .bootstrap-table {
        th, td {
            color: $light-gray !important;
            border-color: $darkmode-border !important;
            background-color: $darkmode-dark !important;
            a {
              color: $light-gray;
              &:hover {
                color: #fff;
              }
            }
        }
        .pagination-info, .page-list {
            color: $light-gray !important;
        }
        .page-item {
            .page-link {
                background-color: $darkmode-secondary !important;
                color: $light-gray !important;
                border-color: $darkmode-border !important;
            }
            &.active {
                .page-link {
                    background-color: $darkmode-primary !important;
                }
            }
        }
    }

    .insight-loader {
        .loading-message {
            color: $light-gray !important;
        }
    }

}
