.nav-pills {
    &.nav-wizard {
        > li {
            max-width: 235px;
            position: relative;
            overflow: visible;
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
 
            + li {
                margin-left: 0;
            }
          
            &:first-child {
                border-left: 0;
            }
 
            &:last-child {
                border-right: 0;
            }
 
            a {
                display: flex;
                align-items: center;
                padding: 0 10px;
                background-color: $light-gray;
                color: $dark;
                font-size: 14px;
                line-height: 14px;
                height: 50px;
                text-align: center;
            }
 
            &:not(:last-child) a:after {
                position: absolute;
                content: "";
                top: 0px;
                right: -20px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 25px 0 25px 20px;
                border-color: transparent transparent transparent $light-gray;
                z-index: 150;
            }
 
            &:not(:first-child) a:before {
                position: absolute;
                content: "";
                top: 0px;
                left: -20px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 25px 0 25px 20px;
                border-color: $light-gray $light-gray $light-gray transparent;
                z-index: 150;
            }

            &.active {
                &:not(:last-child) a:after {
                    border-color: transparent transparent transparent $primary;
                }
 
                &:not(:first-child) a:before {
                    border-color: $primary $primary $primary transparent;
                }
 
                a {
                    background-color: $primary;
                    color: $white;
                    text-decoration: none;
                }
            }

            &:hover {
                &:not(:last-child) a:after {
                    border-color: transparent transparent transparent $secondary;
                }
 
                &:not(:first-child) a:before {
                    border-color: $secondary $secondary $secondary transparent;
                }
 
                a {
                    background-color: $secondary;
                    color: $white;
                    text-decoration: none;
                }
            }
        }
    }
}