#saml-edit-idp {
    .form-check {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    .form-check-input {
        margin-left: 0;
    }

    .form-check-label {
        margin-left: 1.5rem;
    }

    .permission_flags span {
        position: relative;
    }

    .permission_flags span:not(:last-child)::after {
        content: '|';
        margin-left: 5px;
        margin-right: 5px;
    }
}
