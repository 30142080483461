.btn {
    white-space: nowrap;
    
    &.btn-outline-light {
        color: $gray;
        border-color: $gray;
    }

    &.btn-cancel {
        color: $dark;
        text-decoration: none;

        i {
            display: none;
        }

        &:hover {
            i {
                display: block;
            }
            color: $danger;
        }
    }

    &.btn-inline {
        @include media-breakpoint-down(sm) {
            display: inline-block;
            min-width: 49%;
            max-width: 49%;
            margin: 0;
        }
    }
}