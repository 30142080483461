.action-nav {
    height: 60px;
    border-bottom: 1px solid $dark;
    background-color: $white;

    .action-column {
        height: 40px;

        .chart-statistic {
            padding-left: 30px;
            text-align: right;
        
            p {
                margin: 0;
                font-size: 12px;
                line-height: 13px;
                text-transform: uppercase;        
                color: $secondary;
            }
        
            div {
                font-size: 14px;
                line-height: 22px;
                font-weight: bold;
            }

            @include media-breakpoint-down(xl) {
                p {
                    font-size: 10px;
                }
            }

            @include media-breakpoint-down(lg) {
                padding-left: 15px;

                div {
                    font-size: 15px;
                    line-height: 18px;
                }
            }

            @include media-breakpoint-down(md) {
                padding-left: 10px;
                
                p {
                    font-size: 7px;
                }
            }
        }

        i {
            float: right;
            padding-left: 8px;
            margin-top: -8px;
            font-size: 26px;
            color: $gray;
        }

        .action-title {
            display: inline-flex;
            flex-direction: column;
        }

        .action-icon {
            float: left; 
            padding: 0 0 0 15px; 
            
            i {
                font-size: 30px;
                padding-left: 0;
                margin-top: -6px;
            }

            @include media-breakpoint-down(xl) {
               display: none;
            }

            @include media-breakpoint-down(lg) {
                padding: 3px 0 0;
                i {
                    font-size: 30px;
                    margin-top: -9px;
                }

            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .action-title {
            padding-left: 15px;
            text-align: left;
            vertical-align: text-bottom;

            @include media-breakpoint-down(md) {
                padding-left: 0;
            }

            .action-name {
                font-size: 14px;
                line-height: 15px;
                text-transform: uppercase;
            }

            .selected-action {
                font-size: 14px;
                line-height: 22px;
                font-weight: bold;

                @include media-breakpoint-down(lg) {
                    font-size: 10px;

                    i {
                        display: none;
                    }
                }

                @include media-breakpoint-down(sm) {
                    font-size: 14px;

                    i {
                        display: inline-block;
                    }
                }
            }
        }

        .nav {
            .nav-link,
            .dropdown-item {
                font-size: 16px;
                font-weight: bold;
                color: $secondary;
            }
        }

        .filter-nav-toggle {
            &.open {
                i {
                    color: $black !important;
                }
            }
        }
    
    }

    .action-collapse {
        position: absolute;
        left: 0;
        min-width: 350px;
        margin-top: 11px;
        z-index: 998;
        background-color: $light;
        border: 1px solid $gray;
        border-top: none;

        .nav {
            .nav-item {
                border-top: 1px solid $gray;

                .nav-link {
                    padding: 15px 15px 15px 30px;
                    color: $dark;
                    font-size: 16px;

                    i {
                        font-size: 30px;
                    }
                }

                &:first-child {
                    border-top: 0;
                }
            }
        }

        @include media-breakpoint-down(md) {
            min-width: 100%;
            max-width: 100%;
        }
    }

    .dropdown {
        .dropdown-menu {
            .dropdown-item {
                border-bottom: 1px solid $light-gray;
                background-color: transparent;
                font-size: 14px;
    
                &:last-child {
                    border-bottom: none;
                }
    
                label {
                    vertical-align: middle;
                    margin: -15px 0 0;
                    font-size: 14px;
                }
            }
        }

    }
}