.tooltip-content {
    .tooltip-row {
        display: flex;
        justify-content: space-between;

    }

    .series-marker {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: var(--marker-color);
    }

    .tooltip-series-count {
        margin-left: 55px;
        text-align: right;
        font-weight: bold;
    }

    .tooltip-total-row {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid $light-gray;
    }
}
