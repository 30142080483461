.popover {
    .popover-body {
        padding: 0;
        min-width: 240px;
        text-align: center;

        .list-group {
            .list-group-item {
                border: none;
                border-top: 1px solid $light-gray;

                &:first-child {
                    border-top: none;
                }
            }
        }
    }
}