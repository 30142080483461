// Custom variables
@import "variables";

// Mixins
@import "mixins";

// Bootstrap imports
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/print";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
//@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/spinners";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker";
@import '~bootstrap-icons/font/bootstrap-icons.css';

// Forms
@import "forms";

// Utilites
@import "utilites";

// App specific
@import "app";

// Components
@import "./components/btn";
@import "./components/navbar";
@import "./components/main-nav";
@import "./components/filter-nav";
@import "./components/action-nav";
@import "./components/nav-pills";
@import "./components/sidebar";
@import "./components/switch";
@import "./components/popover";
@import "./components/datepicker";
@import "./components/chart-view";
@import "./components/saml-editor";
@import "./components/report";
@import "./components/tooltip";

// Darkmode
@import "darkmode";

// Presentation mode
@import "presentation-mode";
