#infoAccordion {
    .card-header {
        cursor: pointer;

        h5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        i {
            font-size: 20px;
            transition: transform 0.2s ease;
        }
    }

    [aria-expanded="true"] {
        i {
            transform: rotate(180deg);
        }
    }
}
