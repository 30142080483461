$primary:     #365f94;
$secondary:   #565a57;
$success:     #3CA272;
$warning:     #FAC859;
$danger:      #D95350;
$info:        #72C0DE;
$dark:        #333333;
$gray:        #888a87;
$light-gray:  #E9E5E5;
$lighter-gray: #F1F1F1;
$blue:        #2f507f;

//$enable-rounded: false !default;

$font-family-sans-serif: "Nunito", -apple-system,BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$font-size-base: 0.9rem;

$tooltip-color: $dark;
$tooltip-bg: $light-gray;

/* Grid breakpoints */
$grid-breakpoints: (
    xxs: 0,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1180px,
    xxl: 1280px,
    xxxl: 1440px
);
$container-max-widths: (
    xxs: 480px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1140px,
    xxl: 1200px,
    xxxl: 1280px
);